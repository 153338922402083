import { Listbox, Transition } from "@headlessui/react"
import { Region } from "@medusajs/medusa"
import Link from "next/link"
import { CSSProperties, Fragment, useMemo } from "react"
import { ReactCountryFlag } from "react-country-flag"
import { StateType } from "src/hooks/useToggleState"
import { getCountryCodeOrThrow } from "src/localization/state"
import { localizeText } from "src/localization/utils"
import { isBrowser } from "src/utilities/env"

const iconStyle: CSSProperties = {
  width: "20px",
  height: "20px",
}

type CountryOption = {
  countryCode: string
  region: string
  label: string
}

export type CountrySelectProps = {
  toggleState: StateType
  regions: Region[]
}

export const CountrySelect = ({ toggleState, regions }: CountrySelectProps) => {
  const T = localizeText(text)
  const countryCode = getCountryCodeOrThrow()
  const pathWithoutLocale = isBrowser()
    ? window.location.pathname.substring(`/${countryCode}`.length) + window.location.search
    : "/"

  const { state } = toggleState

  const options: CountryOption[] | undefined = useMemo(() => {
    return regions
      ?.flatMap((region) => {
        return region.countries.map<CountryOption>((country) => ({
          countryCode: country.iso_2,
          region: region.id,
          label: country.display_name,
        }))
      })
      .sort((a, b) => a.label.localeCompare(b.label))
  }, [regions])

  const current = options.find((option) => option.countryCode === countryCode)

  return (
    <div className="inline-block">
      <Listbox>
        <Listbox.Button>
          <div className="flex items-center gap-2">
            <span>{T.shippingTo}:</span>

            {current && (
              <>
                <ReactCountryFlag svg style={iconStyle} countryCode={current.countryCode} />

                {current.label}
              </>
            )}
          </div>
        </Listbox.Button>

        <div className="relative">
          <Transition
            show={state}
            as={Fragment}
            leave="transition ease-in duration-150"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Listbox.Options
              className="absolute -bottom-[calc(100%-36px)] left-0 xsmall:left-auto xsmall:right-0 overflow-y-scroll z-[900] drop-shadow-md text-xs font-normal uppercase text-black no-scrollbar rounded-lg w-full max-w-full"
              static
            >
              {options?.map((option, index) => {
                return (
                  <Link key={index} href={`/${option.countryCode}${pathWithoutLocale}`}>
                    <Listbox.Option
                      value={option}
                      className="hover:bg-gray-200 gap-x-2 flex items-center px-3 py-2 bg-white cursor-pointer"
                    >
                      <ReactCountryFlag svg style={iconStyle} countryCode={option.countryCode} /> {option.label}
                    </Listbox.Option>
                  </Link>
                )
              })}
            </Listbox.Options>
          </Transition>
        </div>
      </Listbox>
    </div>
  )
}

const de = {
  shippingTo: "Versand nach",
}

const en: typeof de = {
  shippingTo: "Shipping to",
}

const text = { de, en }
