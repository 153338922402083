"use client"

import { usePathname } from "next/navigation"
import { routes } from "src/config/routes"
import { localizeText } from "src/localization/utils"
import { LocalizedClientLink } from "src/modules/common/components/LocalizedClientLink"
import { PrimaryButton } from "src/modules/common/components/PrimaryButton"

export const CartDropdownEmpty = () => {
  const pathname = usePathname()

  const T = localizeText(text)
  const isHomePage = pathname === routes.home

  return (
    <div>
      <div className="gap-y-4 flex flex-col items-center justify-center py-16">
        <div className="bg-brand-primary flex items-center justify-center w-8 h-8 text-sm font-normal font-semibold text-white rounded-full">
          0
        </div>

        <span>{T.yourShoppingBagIsEmpty}</span>

        {!isHomePage && (
          <LocalizedClientLink href={routes.home} onClick={close}>
            <PrimaryButton as="div" color="primary" size="small">
              {T.exploreProducts}
            </PrimaryButton>
          </LocalizedClientLink>
        )}
      </div>
    </div>
  )
}

const de = {
  yourShoppingBagIsEmpty: "Dein Warenkorb ist leer.",
  exploreProducts: "Produkte entdecken",
}

const en: typeof de = {
  yourShoppingBagIsEmpty: "Your shopping bag is empty.",
  exploreProducts: "Explore products",
}

const text = { de, en }
