import { localizeText } from "src/localization/utils"
import { InputErrorMessage } from "src/modules/common/components/InputErrorMessage"
import { errorText } from "src/modules/common/components/errorText"

export type GenericInputErrorMessageProps = {
  className?: string
}

export const GenericInputErrorMessage = ({ className }: GenericInputErrorMessageProps) => {
  const T = localizeText(errorText)

  return <InputErrorMessage message={T.message} className={className} />
}
