import clsx from "clsx"
import { ComponentProps } from "react"
import { LocalizedClientLink, LocalizedClientLinkProps } from "src/modules/common/components/LocalizedClientLink"
import { Spinner } from "src/modules/common/icons"

export type BaseComponentProps = ComponentProps<"button"> & LocalizedClientLinkProps & ComponentProps<"div">

export type PrimaryButtonProps = BaseComponentProps & {
  loading?: boolean
  as?: "button" | "a" | "div"
  size: "small" | "large"
  color: "primary" | "secondary" | "tertiary"
}

export const PrimaryButton = ({ disabled, loading, as, size, color, children, ...props }: PrimaryButtonProps) => {
  const Element = as === "a" || props.href ? LocalizedClientLink : as || "button"
  const inert = disabled || loading

  return (
    <Element
      {...props}
      disabled={inert}
      className={clsx(
        props.className,
        "hover:transition-none md:w-auto flex items-center gap-2 justify-center w-full font-semibold uppercase transition-[color,background-color,transform] rounded-full",
        {
          "shadow active:scale-95 active:shadow-none": !inert,
          "cursor-not-allowed pointer-events-auto": inert,
          "bg-gray-400 text-white": loading,
          "bg-gray-200 opacity-50": disabled,

          "text-sm py-3 px-6 min-h-10": size === "small",
          "text-lg py-3 px-12 min-h-14": size === "large",

          "bg-brand-primary text-white hover:bg-gray-800 active:bg-gray-600": !inert && color === "primary",
          "bg-brand-secondary-light text-brand-primary hover:bg-brand-secondary-lighter active:bg-white":
            !inert && color === "secondary",
          "bg-white text-gray-800 border hover:bg-gray-800 active:bg-gray-600": !inert && color === "tertiary",
        },
      )}
    >
      {loading ? <Spinner className="w-6 h-6 mx-4" /> : children}
    </Element>
  )
}
