import clsx from "clsx"
import { Spinner } from "src/modules/common/icons"

export const SectionLoadSpinner = () => {
  return (
    <div className="p-4">
      <Spinner />
    </div>
  )
}
