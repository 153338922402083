"use client"

import { Popover, Transition } from "@headlessui/react"
import { Region } from "@medusajs/medusa"
import { Fragment, useMemo } from "react"
import { features } from "src/config/features"
import { routes } from "src/config/routes"
import { useBodyOverflowHidden } from "src/hooks/useBodyOverflowHidden"
import { useOnClickOutside } from "src/hooks/useCloseOnClickOutside"
import { localizeText } from "src/localization/utils"
import { LocalizedClientLink } from "src/modules/common/components/LocalizedClientLink"
import { MenuIcon, XIcon } from "src/modules/common/icons"
import { CopyrightAndCookies } from "src/modules/layout/footer/CopyrightAndCookies"
import { CountrySelectWrapper } from "src/modules/layout/header/CountrySelectWrapper"
import { HeaderLink } from "src/modules/layout/header/HeaderLink"
import { isTruthy } from "src/utilities/typescript"

export type SideMenuProps = {
  regions: Region[]
}

export function SideMenu({ regions }: SideMenuProps) {
  const T = localizeText(text)
  const sideMenuItems = useMemo(() => {
    return [
      { href: routes.home, label: T.sideMenuItems.home },
      { href: routes.products, label: T.sideMenuItems.products },
      { href: routes.search, label: T.sideMenuItems.search },
      features.account && { href: routes.accountRoot, label: T.sideMenuItems.account },
      { href: routes.cart, label: T.sideMenuItems.cart },
    ].filter(isTruthy)
  }, [T])

  return (
    <Popover>
      {({ open, close }) => {
        const ref = useOnClickOutside(close)
        useBodyOverflowHidden(open)

        return (
          <>
            <Popover.Button aria-label={T.menu}>
              <HeaderLink href="" label={T.menu}>
                <MenuIcon size={24} />
              </HeaderLink>
            </Popover.Button>

            <Transition
              show={open}
              as={Fragment}
              enter="transition ease-out duration-150"
              enterFrom="opacity-0"
              enterTo="opacity-100 backdrop-blur-2xl"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 backdrop-blur-2xl"
              leaveTo="opacity-0"
            >
              <Popover.Panel
                className="backdrop-blur-2xl absolute inset-0 z-50 flex flex-col w-screen h-screen p-2 text-sm text-white"
                ref={ref}
              >
                <div className="flex flex-col h-full bg-[rgba(3,7,18,0.5)] rounded-lg justify-between p-6 max-w-md">
                  <button type="button" onClick={close} title={T.close} className="flex justify-end">
                    <XIcon size={24} />
                  </button>

                  <ul className="flex flex-col items-start justify-start gap-6">
                    {sideMenuItems.map(({ href, label }) => {
                      return (
                        <li key={href}>
                          <LocalizedClientLink
                            href={href}
                            onClick={close}
                            className="hover:text-gray-200 text-h2 flex leading-10"
                          >
                            {label}
                          </LocalizedClientLink>
                        </li>
                      )
                    })}
                  </ul>

                  <div className="gap-4 flex flex-col">
                    <CountrySelectWrapper regions={regions} />
                    <CopyrightAndCookies />
                  </div>
                </div>
              </Popover.Panel>
            </Transition>
          </>
        )
      }}
    </Popover>
  )
}

const de = {
  menu: "Menü",
  close: "Schließen",
  sideMenuItems: {
    home: "Startseite",
    products: "Alle Boxen",
    search: "Suche",
    account: "Konto",
    cart: "Warenkorb",
  },
}

const en: typeof de = {
  menu: "Menu",
  close: "Close",
  sideMenuItems: {
    home: "Home",
    products: "Alle Boxen",
    search: "Search",
    account: "Account",
    cart: "Cart",
  },
}

const text = { de, en }
