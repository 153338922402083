"use client"

import { GoogleAnalyticsTag } from "src/integrations/google/GoogleAnalyticsTag"
import { useTrackPageViews } from "../tracking/api"
// import { AdcellTag } from "./adcell/AdcellTag"
import { Ccm19Tag } from "./ccm19/Ccm19Tag"
import { FraudBlockerTag } from "./fraudBlocker/FraudBlockerTag"
// import { GoogleAnalyticsTag } from "./google/GoogleAnalyticsTag"
import { KlaviyoTag } from "./klaviyo/KlaviyoTag"
// import { TrustedShopsTag } from "./trustedShops/TrustedShopsTag"
import { TypeformTag } from "./typeform/TypeformTag"

export const Integrations = () => {
  useTrackPageViews()

  return (
    <>
      <Ccm19Tag />
      <GoogleAnalyticsTag />
      <KlaviyoTag />
      <TypeformTag />
      {/* <AdcellTag /> */}
      <FraudBlockerTag />
      {/* <TrustedShopsTag /> */}
    </>
  )
}
