"use client"

import { useEffect, useState } from "react"
import ReactDom from "react-dom"
import { useIsClient } from "src/hooks/useIsClient"
import { useCcm19EmbeddingAccepted } from "../ccm19/useConsented"
import { integrationDebug } from "./api"
import { integrationConsentId } from "./config"
import { setIntegrationLoadState } from "./loaded"
import { useIntegrationNeeded } from "./needed"
import { IntegrationId } from "./types"

export type IntegrationScriptProps = {
  id: IntegrationId
  src: string
  onlyWhenNeeded?: boolean
  autoConsent?: boolean
  onLoad?: () => void
  [key: string]: any
}

export const IntegrationScript = ({
  id,
  src,
  onlyWhenNeeded,
  autoConsent,
  onLoad,
  ...attributes
}: IntegrationScriptProps) => {
  const [inserted, setInserted] = useState(false)
  const consentName = integrationConsentId[id]
  const consented = useCcm19EmbeddingAccepted(consentName)
  const needed = useIntegrationNeeded(id)
  const isClient = useIsClient()

  // biome-ignore lint/correctness/useExhaustiveDependencies:
  useEffect(() => {
    setInserted(false)
  }, [src])

  if (!isClient) {
    return null
  }

  if (inserted) {
    return null
  }

  if (!consented && !autoConsent) {
    return null
  }

  if (onlyWhenNeeded && !needed) {
    return null
  }

  const script = document.createElement("script")

  script.id = id
  script.src = src
  script.async = true
  script.referrerPolicy = "origin"

  for (const [key, value] of Object.entries(attributes)) {
    script.setAttribute(key, String(value))
  }

  script.onload = (event) => {
    integrationDebug(id, "loaded", event)
    setIntegrationLoadState(id, "loaded")
    onLoad?.()
  }

  script.onerror = (event) => {
    integrationDebug(id, "errored", event)
    setIntegrationLoadState(id, "errored")
  }

  document.body.appendChild(script)

  setInserted(true)

  return null
}
