import { useEffect, useRef } from "react"
import { useEventCallback } from "src/hooks/useEventCallback"

export function useOnClickOutside(callback: () => void) {
  const ref = useRef(null)

  const memoizedCallback = useEventCallback(callback)

  // close modal on outside click
  useEffect(() => {
    const handleOutsideClick = (event: MouseEvent) => {
      if (event.target === ref.current) {
        memoizedCallback()
      }
    }

    window.addEventListener("click", handleOutsideClick)
    return () => {
      window.removeEventListener("click", handleOutsideClick)
    }
  }, [memoizedCallback])

  return ref
}
