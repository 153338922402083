"use client"

import { LocalizedClientLink, LocalizedClientLinkProps } from "src/modules/common/components/LocalizedClientLink"
import { trackProductLinkClick } from "src/tracking/api"
import { ListProductData } from "src/types/global"

export type ProductLinkProps = LocalizedClientLinkProps & {
  listId: string
  product: ListProductData
}

/**
 * Use this component to link to product detail pages.
 */
export const ProductLink = ({ listId, product, ...props }: ProductLinkProps) => {
  const onClick = () => {
    trackProductLinkClick(listId, product)
    props.onClick?.()
  }

  return (
    <LocalizedClientLink {...props} onClick={onClick}>
      {props.children}
    </LocalizedClientLink>
  )
}
