"use client"

import { Region } from "@medusajs/medusa"
import { useToggleState } from "@medusajs/ui"
import clsx from "clsx"
import { ArrowRightIcon } from "src/modules/common/icons"
import { CountrySelect } from "src/modules/layout/header/CountrySelect"

export const CountrySelectWrapper = ({ regions }: { regions: Region[] }) => {
  const toggleState = useToggleState()

  if (regions?.length < 2) {
    return null
  }

  return (
    <div
      className="flex items-center justify-center lg:justify-start gap-2"
      onMouseEnter={toggleState.open}
      onMouseLeave={toggleState.close}
    >
      <CountrySelect toggleState={toggleState} regions={regions} />
      <ArrowRightIcon className={clsx("transition-transform duration-150", toggleState.state ? "-rotate-90" : "")} />
    </div>
  )
}
