import { localizeText } from "src/localization/utils"
import { META_DATA } from "src/metadata/constants"
import { CookieSettingsButton } from "src/modules/layout/footer/CookieSettingsButton"

export const CopyrightAndCookies = () => {
  const T = localizeText(text)

  return (
    <section
      aria-label="Cookies und Copyright"
      className="flex flex-col items-center lg:items-start gap-4 whitespace-nowrap text-sm"
    >
      <CookieSettingsButton />

      <div className="opacity-75">
        © {META_DATA.address.companyName}. {T.allRightsReserved}.
      </div>
    </section>
  )
}

const de = {
  allRightsReserved: "Alle Rechte vorbehalten",
}

const en: typeof de = {
  allRightsReserved: "All rights reserved",
}

const text = { de, en }
