"use client"

import { Region } from "@medusajs/medusa"
import { useAsyncEffect } from "ahooks"
import { updateCart } from "src/data/medusa/carts"
import { getCountryCodeOrThrow } from "src/localization/state"
import { setCart, useCart } from "src/modules/(checkout)/cart/state"

export type AutoFixCartRegionProps = {
  regions: Region[]
}

export const AutoFixCartRegion = ({ regions }: AutoFixCartRegionProps) => {
  const cart = useCart()

  const countryCode = getCountryCodeOrThrow()
  const region = regions.find((region) => region.countries.some((country) => country.iso_2 === countryCode))

  // auto-fix cart region and country code
  useAsyncEffect(async () => {
    if (!cart || !region) {
      return
    }

    if (cart.region_id !== region.id || cart.shipping_address?.country_code !== countryCode) {
      const newCart = await updateCart(cart.id, { region_id: region.id, country_code: countryCode })

      setCart(newCart)
    }
  }, [cart, region, countryCode])

  return null
}
