import { isBrowser } from "src/utilities/env"

// This must be a shared object and not a usePrevious() call because we want to
// limit actions to once per page, not once per component.
const memory = new Set<string>() // <id>

export function runOnceInBrowser(id: string, callback: () => void) {
  if (!isBrowser()) {
    return
  }

  const completed = memory.has(id)

  if (completed) {
    return
  }

  callback()

  memory.add(id)
}
