import { LineItem, Region } from "@medusajs/medusa"
import clsx from "clsx"
import { formatAmount } from "src/utilities/price"

export type LineItemUnitPriceProps = {
  item: LineItem
  region: Region
  countryCode: string
}

export const LineItemUnitPrice = ({ item, region, countryCode }: LineItemUnitPriceProps) => {
  const hasReducedPrice = item.total !== item.original_total

  return (
    <div className="flex flex-col items-center justify-center h-full">
      {hasReducedPrice && (
        <div className="text-muted line-through">
          {formatAmount({
            amount: (item.original_total || 0) / item.quantity,
            region: region,
            countryCode,
          })}
        </div>
      )}

      <div
        className={clsx({
          "text-discount": hasReducedPrice,
        })}
      >
        {formatAmount({
          amount: (item.total || 0) / item.quantity,
          region: region,
          countryCode,
        })}
      </div>
    </div>
  )
}
