"use client"

import { useState } from "react"
import { localizeText } from "src/localization/utils"
import { GenericInputErrorMessage } from "src/modules/common/components/GenericInputErrorMessage"
import { Spinner, TrashIcon } from "src/modules/common/icons"

export type DeleteButtonProps = {
  onClick: () => Promise<unknown>
  label?: string
  showText?: boolean
}

export const DeleteButton = ({ onClick, label, showText }: DeleteButtonProps) => {
  const T = localizeText(text)
  const [pending, setPending] = useState(false)
  const [failed, setFailed] = useState(false)

  const handleClick = async () => {
    try {
      setPending(true)
      setFailed(false)
      await onClick()
    } catch (error) {
      console.error(error)
      setFailed(true)
    } finally {
      setPending(false)
    }
  }

  return (
    <div className="flex flex-col items-center justify-between gap-4 text-xs font-normal">
      <button
        type="button"
        className="gap-x-1 text-subtle hover:text-default flex items-center gap-2 cursor-pointer"
        onClick={handleClick}
        disabled={pending}
        title={label || T.remove}
        aria-label={label || T.remove}
      >
        {pending ? <Spinner /> : <TrashIcon />}
        {showText && <span>{T.remove}</span>}
      </button>

      {failed && <GenericInputErrorMessage className="!m-0" />}
    </div>
  )
}

const de = {
  remove: "Entfernen",
}

const en: typeof de = {
  remove: "Remove",
}

const text = { de, en }
