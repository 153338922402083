import Script from "next/script"
import { IntegrationScript } from "src/integrations/lib/IntegrationScript"
import { KLAVIYO_PUBLIC_API_KEY } from "./config"

const src = `https://static.klaviyo.com/onsite/js/${KLAVIYO_PUBLIC_API_KEY}/klaviyo.js`

export const KlaviyoTag = () => {
  return (
    <>
      <IntegrationScript id="klaviyo" src={src} onlyWhenNeeded />

      {/* https://developers.klaviyo.com/en/docs/introduction_to_the_klaviyo_object#how-to-load-the-klaviyo-object */}
      <Script
        id="klaviyo-object"
        dangerouslySetInnerHTML={{
          __html: `!function(){if(!window.klaviyo){window._klOnsite=window._klOnsite||[];try{window.klaviyo=new Proxy({},{get:function(n,i){return"push"===i?function(){var n;(n=window._klOnsite).push.apply(n,arguments)}:function(){for(var n=arguments.length,o=new Array(n),w=0;w<n;w++)o[w]=arguments[w];var t="function"==typeof o[o.length-1]?o.pop():void 0,e=new Promise((function(n){window._klOnsite.push([i].concat(o,[function(i){t&&t(i),n(i)}]))}));return e}}})}catch(n){window.klaviyo=window.klaviyo||[],window.klaviyo.push=function(){var n;(n=window._klOnsite).push.apply(n,arguments)}}}}();`,
        }}
      />
    </>
  )
}
