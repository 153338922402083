import { ProductVariant } from "@medusajs/medusa"
import { PricedVariant } from "@medusajs/medusa/dist/types/pricing"
import { PartialLineItem } from "src/types/medusa"

export function isGiftCard(item: Pick<PartialLineItem, "is_giftcard" | "title">) {
  // We could use a product type to determine if an item is a gift card but the
  // card object the service receives only has the product type id, not the full
  // product type object. We could fetch the product type object but that would
  // require an additional request for each item in the cart.

  return item.is_giftcard || item.title === "Gutschein"
}

export function isLineItemDigital(item: PartialLineItem) {
  return item.description?.includes("Digital")
}

export function isVariantDigital(variant: ProductVariant | PricedVariant | null | undefined) {
  return variant?.title?.includes("Digital")
}

export function qualifiesForFreeShipping(price: number) {
  return price >= 4900
}

export function isDummyVariant(variant: ProductVariant | PricedVariant | null | undefined) {
  return variant?.title === "Standard"
}
