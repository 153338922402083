import { Ccm19EmbeddingName } from "src/integrations/ccm19/embeddings"
import { IntegrationId } from "./types"

export const integrationConsentId: Record<IntegrationId, Ccm19EmbeddingName> = {
  // adcell: Ccm19EmbeddingName.ADCELL,
  // adcell_category: Ccm19EmbeddingName.ADCELL,
  // adcell_conversion: Ccm19EmbeddingName.ADCELL,
  // adcell_home: Ccm19EmbeddingName.ADCELL,
  // adcell_product: Ccm19EmbeddingName.ADCELL,
  // adcell_purchase: Ccm19EmbeddingName.ADCELL,
  // adcell_search: Ccm19EmbeddingName.ADCELL,
  ccm19: Ccm19EmbeddingName.CCM19,
  // crisp: Ccm19EmbeddingName.Crisp,
  fraudblocker: Ccm19EmbeddingName.FraudBlocker,
  fraudblocker_conversion: Ccm19EmbeddingName.FraudBlocker,
  google_analytics: Ccm19EmbeddingName.GoogleAnalytics,
  klaviyo: Ccm19EmbeddingName.Klaviyo,
  // sovendus: Ccm19EmbeddingName.Sovendus,
  // trustedshops: Ccm19EmbeddingName.TrustedShops,
  typeform: Ccm19EmbeddingName.Typeform,
}
