"use client"

import { Fragment } from "react"
import { routes } from "src/config/routes"
import { getCountryCodeOrThrow } from "src/localization/state"
import { localizeText } from "src/localization/utils"
import { removeItemFromCart } from "src/modules/(checkout)/cart/api"
import { setCart, useCart } from "src/modules/(checkout)/cart/state"
import { compareLineItems, formatTotals } from "src/modules/(checkout)/cart/utils"
import { DeleteButton } from "src/modules/common/components/DeleteButton"
import { Divider } from "src/modules/common/components/Divider"
import { LineItemUnitPrice } from "src/modules/common/components/LineItemUnitPrice"
import { LineItemVariant } from "src/modules/common/components/LineItemVariant"
import { LocalizedClientLink } from "src/modules/common/components/LocalizedClientLink"
import { PrimaryButton } from "src/modules/common/components/PrimaryButton"
import { totalsText } from "src/modules/common/components/totalsText"
import { Thumbnail } from "src/modules/product/components/Thumbnail"
import { SectionLoadSpinner } from "src/modules/skeletons/components/SectionLoadSpinner"

export const CartDropdownItems = () => {
  const cart = useCart()

  if (!cart) {
    return <SectionLoadSpinner />
  }

  const T = localizeText(text)
  const countryCode = getCountryCodeOrThrow()
  const { taxTotal, total } = formatTotals(cart)

  return (
    <>
      <div className="max-h-[402px] px-4 flex flex-col gap-4 overflow-y-auto overflow-x-hidden pt-4">
        {cart.items.sort(compareLineItems).map((item) => {
          const href = routes.format_product(item.variant.product.handle)

          return (
            <Fragment key={item.id}>
              <div className="grid grid-cols-[auto,1fr,auto] items-center gap-x-4 gap-y-2">
                <LocalizedClientLink href={href} className="w-24 row-span-2">
                  <Thumbnail src={item.thumbnail} square />
                </LocalizedClientLink>

                <div>
                  <LocalizedClientLink href={href}>
                    <div className="font-medium truncate">{item.title}</div>
                  </LocalizedClientLink>

                  <LineItemVariant item={item} />
                </div>

                <div className="gap-x-1 flex items-center col-start-3 row-start-1 text-sm font-normal">
                  {item.quantity > 1 && <p>{item.quantity}x </p>}
                  <LineItemUnitPrice item={item} region={cart.region} countryCode={countryCode} />
                </div>

                <div className="col-span-2 col-start-2 row-start-2 mr-auto">
                  <DeleteButton
                    onClick={async () => {
                      const newCart = await removeItemFromCart({
                        oldCart: cart,
                        lineId: item.id,
                      })
                      setCart(newCart)
                    }}
                    showText
                  />
                </div>
              </div>

              <Divider />
            </Fragment>
          )
        })}
      </div>

      <div className="flex flex-col gap-5 p-4 text-sm font-normal">
        <div className="flex items-center justify-between">
          <div>
            <div className="font-semibold">{T.total}</div>
            <div className="text-subtle">{T.format_taxLine(taxTotal)}</div>
          </div>

          <div className="text-lg font-semibold">{total}</div>
        </div>

        <LocalizedClientLink href={routes.cart}>
          <PrimaryButton as="div" color="primary" size="small">
            {T.goToCart}
          </PrimaryButton>
        </LocalizedClientLink>
      </div>
    </>
  )
}

const de = {
  ...totalsText.de,
  goToCart: "Zum Warenkorb",
}

const en: typeof de = {
  ...totalsText.en,
  goToCart: "Go to cart",
}

const text = { de, en }
