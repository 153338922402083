import { IntegrationScript } from "src/integrations/lib/IntegrationScript"
import { FRAUD_BLOCKER_SID } from "./config"

const src = `https://monitor.fraudblocker.com/fbt.js?sid=${FRAUD_BLOCKER_SID}`

export const FraudBlockerTag = () => {
  return (
    <>
      <IntegrationScript id="fraudblocker" src={src} />

      <noscript>
        <a href="https://fraudblocker.com" rel="nofollow">
          <img src={src} alt="Fraud Blocker" />
        </a>
      </noscript>
    </>
  )
}
