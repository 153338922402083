import { Query, SORT_OPTIONS, SortOption } from "./types"

const paramName = {
  search: "suche",
  sortBy: "sortierung",
  page: "seite",
}

export function parseQuery(segments: string[] | undefined) {
  if (segments && segments.length > 1) {
    throw new Error("Only zero or one segments are allowed: " + JSON.stringify(segments))
  }

  const query = decodeURIComponent(segments?.join("/") || "")
  const params = new URLSearchParams(query)
  const search = params.get(paramName.search) || ""
  const sortBy_unverified = params.get(paramName.sortBy)
  const sortBy = SORT_OPTIONS.includes(sortBy_unverified as any) ? (sortBy_unverified as SortOption) : "created_at"
  const page = +(params.get(paramName.page) || 1)

  return { search, sortBy, page }
}

export type ProductListQuery = ReturnType<typeof parseQuery>

export function formatQuery(query: Partial<Query>) {
  const params = new URLSearchParams()

  if (query.search) {
    params.set(paramName.search, query.search)
  }

  if (query.sortBy && query.sortBy !== "created_at") {
    params.set(paramName.sortBy, query.sortBy)
  }

  if (query.page && query.page !== 1) {
    params.set(paramName.page, query.page.toString())
  }

  return params.toString()
}
