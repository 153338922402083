import { useEffect } from "react"
import { updateUserData } from "src/integrations/lib/userData"
import { reportError } from "src/utilities/error"
import { create as createStore } from "zustand"
import { ccm19Debug, ccm19GetAcceptedEmbeddings } from "./api"
import { Ccm19Event } from "./constants"
import { Ccm19EmbeddingName, ccm19DefaultConsent } from "./embeddings"
import { Ccm19EmbeddingAcceptedEvent, consentNames } from "./types"

const store = createStore<Record<string, boolean>>(() => ccm19DefaultConsent)

function setConsent(name: Ccm19EmbeddingName, accepted: boolean) {
  const finalAccepted = accepted || ccm19DefaultConsent[name]

  store.setState({ [name]: finalAccepted })
  ccm19Debug(name, finalAccepted ? "🟢" : "🔴")
}

export function useCcm19EventHandling() {
  useEffect(() => {
    const handler = async (event: CustomEvent<Ccm19EmbeddingAcceptedEvent>) => {
      const name = event.detail.name

      setConsent(name, true)

      if (!consentNames.includes(name)) {
        reportError(`Unknown consent name: "${name}"`)
      }

      updateUserData()
    }

    window.addEventListener(Ccm19Event.EmbeddingAccepted, handler as any)
    return () => window.removeEventListener(Ccm19Event.EmbeddingAccepted, handler as any)
  }, [])

  useEffect(() => {
    const handler = async () => {
      const acceptedEmbeddings = ccm19GetAcceptedEmbeddings()

      for (const name of consentNames) {
        const consented = acceptedEmbeddings.some((embedding) => embedding.name === name)

        setConsent(name, consented)
      }

      updateUserData()
    }

    window.addEventListener(Ccm19Event.WidgetClosed, handler)
    return () => window.removeEventListener(Ccm19Event.WidgetClosed, handler)
  }, [])
}

export function useCcm19EmbeddingAccepted(name: Ccm19EmbeddingName) {
  return store((state) => state[name])
}
