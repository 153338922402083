"use client"

import { LineItem } from "@medusajs/medusa"
import clsx from "clsx"
import { getCountryCodeOrThrow } from "src/localization/state"
import { useCart } from "src/modules/(checkout)/cart/state"
import { skeleton } from "src/modules/skeletons/skeletonStyles"
import { formatAmount } from "src/utilities/price"
import { isDummyVariant } from "src/utilities/product"

export type LineItemVariantProps = {
  item: LineItem
  initializing?: boolean
}

export const LineItemVariant = ({ item, initializing }: LineItemVariantProps) => {
  const cart = useCart()

  if (!cart || isDummyVariant(item.variant)) {
    return null
  }

  const countryCode = getCountryCodeOrThrow()
  const title = item.variant.product.is_giftcard
    ? formatAmount({
        amount: +(item.variant.options[0]?.value || 0),
        region: cart.region,
        countryCode,
        short: true,
      })
    : item.variant.title

  return <span className={clsx("text-sm", initializing && skeleton.text)}>{title}</span>
}
