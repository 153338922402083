"use client"

import { Popover, Transition } from "@headlessui/react"
import { usePathname } from "next/navigation"
import { Fragment, useEffect } from "react"
import { routes } from "src/config/routes"
import { useIsClient } from "src/hooks/useIsClient"
import { localizeText } from "src/localization/utils"
import { useCart } from "src/modules/(checkout)/cart/state"
import { Divider } from "src/modules/common/components/Divider"
import { ShoppingBagIcon } from "src/modules/common/icons"
import { CartDropdownEmpty } from "src/modules/layout/header/CartDropdownEmpty"
import { CartDropdownItems } from "src/modules/layout/header/CartDropdownItems"
import { HeaderLink } from "src/modules/layout/header/HeaderLink"
import { SectionLoadSpinner } from "src/modules/skeletons/components/SectionLoadSpinner"
import { create as createStore } from "zustand"

const store = createStore(() => ({
  isOpen: false,
}))

let openTimeout: any

function close() {
  clearTimeout(openTimeout)
  store.setState({ isOpen: false })
}

function open() {
  clearTimeout(openTimeout)
  store.setState({ isOpen: true })
}

export function openCartDropdownForFiveSeconds() {
  open()
  openTimeout = setTimeout(close, 5000)
}

export const CartDropdown = () => {
  const isClient = useIsClient()
  const cart = useCart()
  const pathname = usePathname()
  const isOpen = store((state) => state.isOpen)

  const T = localizeText(text)
  const totalItems = cart?.items?.reduce((sum, item) => sum + item.quantity, 0) || 0
  const isCartOrCheckoutPage = pathname.includes(routes.cart) || pathname.includes(routes.checkout)

  // Clean up the timer when the component unmounts.
  useEffect(() => {
    if (isCartOrCheckoutPage) {
      close()
    }
  }, [isCartOrCheckoutPage])

  const initializing = !isClient || !cart
  const empty = !initializing && !cart.items.length

  function handleMouseEnter() {
    if (!isCartOrCheckoutPage) {
      open()
    }
  }

  const label = `${T.cart} (${totalItems})`

  return (
    <div className="z-10" onMouseEnter={handleMouseEnter} onMouseLeave={close}>
      <Popover className="relative h-full">
        <Popover.Button className="relative" tabIndex={-1}>
          <HeaderLink href={routes.cart} label={label}>
            <ShoppingBagIcon size={24} />

            {!!totalItems && (
              <div className="bg-brand-primary absolute top-0 right-0 flex items-center justify-center w-5 h-5 text-xs text-white rounded-full shadow">
                {totalItems}
              </div>
            )}
          </HeaderLink>
        </Popover.Button>

        <Transition
          show={isOpen}
          as={Fragment}
          enter="transition ease-out duration-200"
          enterFrom="opacity-0 translate-y-1"
          enterTo="opacity-100 translate-y-0"
          leave="transition ease-in duration-150"
          leaveFrom="opacity-100 translate-y-0"
          leaveTo="opacity-0 translate-y-1"
        >
          <Popover.Panel
            static
            className="hidden lg:block absolute top-full translate-y-4 right-0 translate-x-4 border-x border-t border-b w-[452px] text-default bg-white rounded-b-2xl shadow-2xl"
          >
            <div className="flex items-center justify-center p-4">
              <h3 className="text-h5">{T.cart}</h3>
            </div>

            <Divider />

            {initializing ? (
              <div className="flex items-center justify-center w-full py-20">
                <SectionLoadSpinner />
              </div>
            ) : empty ? (
              <CartDropdownEmpty />
            ) : (
              <CartDropdownItems />
            )}
          </Popover.Panel>
        </Transition>
      </Popover>
    </div>
  )
}

const de = {
  cart: "Warenkorb",
}

const en: typeof de = {
  cart: "Cart",
}

const text = { de, en }
